<template>
  <div class="p-5">
    <fw-panel :title="$t('share_document')">
      <b-tabs v-model="tab" :animated="false" @input="changeTab">
        <b-tab-item :label="$t('people')" value="people">
          <div class="max-h-52 overflow-y-auto">
            <div v-for="user in users" :key="user.id" class="flex gap-2 py-2 px-2 items-center">
              <div class="flex-1 text-sm font-semibold">{{ user.email }}</div>
              <b-dropdown
                v-if="canChangeRoles && user.key != me.key"
                scrollable
                max-height="500"
                aria-role="list"
                append-to-body
              >
                <fw-button slot="trigger" type="xlight" icon="chevron-down">
                  {{ rolesLabels[user.role][language] }}
                </fw-button>
                <b-dropdown-item
                  v-for="option in availableRoles"
                  :key="user.key + option"
                  aria-role="menu-item"
                  :focusable="false"
                  @click="changeRoleUser(user, option)"
                >
                  {{ rolesLabels[option][language] }}
                </b-dropdown-item>
              </b-dropdown>
              <fw-tag v-else size="xs">{{ rolesLabels[user.role][language] }}</fw-tag>
              <b-dropdown
                v-if="canChangeRoles && user.key != me.key"
                scrollable
                max-height="500"
                aria-role="list"
                append-to-body
                position="is-bottom-left"
              >
                <fw-icon-more slot="trigger" class="text-gray-600"></fw-icon-more>
                <b-dropdown-item aria-role="menu-item" :focusable="false" @click="removeUser(user)">
                  {{ $t('remove_user') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-if="users.length == 0" class="flex justify-center pt-6 pb-3 text-gray-400 text-sm">
              {{ $t('no_active_shares') }}
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="$t('invites') + ' (' + invites.length + ')'" value="invites">
          <div class="max-h-52 overflow-y-auto">
            <div v-for="invite in invites" :key="invite.id" class="flex gap-2 py-2 px-2 items-center">
              <fw-icon-mail class="h-4 w-4" />
              <div class="flex-1 text-sm font-semibold">{{ invite.email }}</div>
              <fw-tag size="xs">{{ rolesLabels[invite.role][language] }}</fw-tag>
            </div>
            <div v-if="invites.length == 0" class="flex justify-center pt-6 pb-3 text-gray-400 text-sm">
              {{ $t('no_invites_sent') }}
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <div v-if="canInviteUsers" class="border mb-3 border-gray-100 rounded-md">
        <div class="flex gap-2 py-2 pl-4 pr-2 cursor-pointer" @click="openCreateInvite()">
          <div class="flex-1">
            <fw-label>{{ $t('invite_by_email') }}</fw-label>
          </div>
          <fw-button type="xlight">
            <fw-icon-add v-if="!addUser" class="h-4 w-4" />
            <fw-icon-close v-else class="h-4 w-4" />
          </fw-button>
        </div>
        <div v-if="addUser" class="flex gap-2 px-4">
          <b-field :label="$t('email')" class="flex-1">
            <b-input ref="inviteemail" v-model="email" :disable="creating_invite"></b-input>
          </b-field>
          <b-field :label="$t('role')">
            <b-select v-model="role" :disable="creating_invite" :placeholder="$t('select_a_role')">
              <option v-for="option in availableRoles" :key="option" :value="option">
                {{ rolesLabels[option][language] }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="addUser" class="px-4 pb-4 pt-2">
          <fw-button type="primary" :loading="creating_invite" @click.native="createInvite()">{{
            $t('invite')
          }}</fw-button>
        </div>
      </div>
      <div v-if="canActivateLink || linkShare" class="border border-gray-100 rounded-md">
        <div class="flex gap-2 py-2 pl-4 items-center">
          <div class="flex-1">
            <fw-label>{{ $t('share_by_link') }}</fw-label>
          </div>
          <b-switch v-if="canActivateLink" v-model="linkShare" :disabled="loading" />
        </div>
        <div v-if="linkShare" class="flex gap-2 w-full items-center pl-4 pr-1 pb-2">
          <div class="flex-1 line-clamp-1 cursor-pointer" @click="toClipboardDocumentLink">{{ documentLink }}</div>
          <div>
            <b-tooltip :label="$t('copy_document_link')" type="is-light">
              <fw-button :type="'link'" @click.native="toClipboardDocumentLink">
                <fw-icon-check v-if="copied" class="h-5 w-5"></fw-icon-check>
                <fw-icon-copy v-else class="h-5 w-5"></fw-icon-copy>
              </fw-button>
            </b-tooltip>
          </div>
        </div>
      </div>
    </fw-panel>
  </div>
</template>
<script>
import ServicePages from '../../services/ServicePages'
export default {
  name: 'ModalShare',
  props: {
    pageKey: {
      type: String,
      required: true,
    },
    versionKey: {
      type: String,
      required: true,
    },
    myrole: {
      type: String,
      required: true,
    },
    translateMode: {
      type: Boolean,
      default: false,
    },
    isShareLinkActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      role: 'viewer',
      availableRoles: ['viewer', 'reviewer', 'editor', 'manager'],
      rolesLabels: {
        viewer: {
          pt: 'Visualizador',
          en: 'Viewer',
        },
        reviewer: {
          pt: 'Revisor',
          en: 'Reviewer',
        },
        editor: {
          pt: 'Editor',
          en: 'Editor',
        },
        manager: {
          pt: 'Gestor',
          en: 'Manager',
        },
      },
      invites: [],
      users: [],
      addUser: false,
      linkShare: false,
      documentLink:
        'https://' +
        window.location.host +
        '/pages/' +
        (this.translateMode ? 'translate' : 'edit') +
        '/' +
        this.pageKey +
        '/' +
        this.versionKey,
      copied: false,
      clipboard_timer: null,
      creating_invite: false,
      tab: 'people',
      loading: false,
    }
  },
  computed: {
    me() {
      return this.$store.getters.getUser
    },
    canInviteUsers() {
      return this.myrole == 'manager' || this.myrole == 'owner'
    },
    canChangeRoles() {
      return this.myrole == 'owner' || this.myrole == 'manager'
    },
    canActivateLink() {
      return this.myrole == 'owner'
    },
    language() {
      return this.$i18n.locale
    },
  },
  watch: {
    async linkShare(val) {
      //change link share
      this.loading = true
      try {
        let result = await ServicePages.setPageShareLink(this.pageKey, val)
        console.log('setPageShareLink', result)
      } catch (e) {
        console.log('setPageShareLink', e)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.loadInvites()
    this.linkShare = this.isShareLinkActive
  },
  methods: {
    async removeUser(user) {
      try {
        let result = await ServicePages.deleteInvite(this.pageKey, user.invite_key)
        console.log('changeRoleInvite', result)
      } catch (e) {
        console.log('changeRoleInvite', e)
      } finally {
        this.loadInvites()
      }
    },
    async changeRoleInvite(invite, role) {
      try {
        let result = await ServicePages.createInvite(this.pageKey, this.email, role)
        console.log('changeRoleInvite', result)
      } catch (e) {
        console.log('changeRoleInvite', e)
      } finally {
        this.loadInvites()
      }
    },
    async changeRoleUser(user, role) {
      try {
        let result = await ServicePages.changePageUserRole(this.pageKey, user.key, role)
        console.log('changeUserRole', result)
      } catch (e) {
        console.log('changeUserRole', e)
      } finally {
        this.loadInvites()
      }
    },
    changeTab(tab) {
      this.tab = tab
    },
    toClipboardDocumentLink() {
      this.$copyText(this.documentLink).then(() => {
        this.copied = true

        if (this.clipboard_timer !== null) {
          clearTimeout(this.clipboard_timer)
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t('link_copied'),
            position: 'is-top',
          })
        }

        this.clipboard_timer = setTimeout(() => {
          this.copied = false
          this.clipboard_timer = null
        }, 3000)
      })
    },
    toggleLinkShare() {
      this.linkShare = !this.linkShare
    },
    openCreateInvite() {
      this.addUser = !this.addUser
      if (this.addUser) {
        this.$nextTick(() => {
          this.$refs.inviteemail.focus()
        })
      }
    },
    async createInvite() {
      this.creating_invite = true
      //validate if email is valid and role is filled
      try {
        let result = await ServicePages.createInvite(this.pageKey, this.email, this.role)
        console.log('createInvite', result)
      } catch (e) {
        console.log('createInvite', e)
      } finally {
        this.creating_invite = false
        this.addUser = false
        this.email = ''
        this.role = 'viewer'
        this.loadInvites()
      }
    },
    async loadInvites() {
      //load invites
      let result = await ServicePages.getInvites(this.pageKey)
      console.log('loadInvites', result)
      this.invites = result.invites
      this.users = result.users
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "people": "Pessoas",
      "invites": "Convites",
      "no_active_shares": "Não há partilhas ativas.",
      "no_invites_sent": "Não foram enviados convites.",
      "invite_by_email": "Convidar por email",
      "email": "Email",
      "role": "Função",
      "select_a_role": "Selecione uma função",
      "invite": "Convidar",
      "share_by_link": "Partilhar acesso de leitura por link",
      "copy_document_link": "Copiar link do documento",
      "link_copied": "Link copiado para a área de transferência",
      "remove_user": "Remover utilizador",
      "share_document": "Partilhar documento"
    },
    "en": {
      "people": "People",
      "invites": "Invites",
      "no_active_shares": "No active shares.",
      "no_invites_sent": "No invites sent.",
      "invite_by_email": "Invite by email",
      "email": "Email",
      "role": "Role",
      "select_a_role": "Select a role",
      "invite": "Invite",
      "share_by_link": "Share read access by link",
      "copy_document_link": "Copy document link",
      "link_copied": "Link copied to clipboard",
      "remove_user": "Remove user",
      "share_document": "Share document"
    }
  }
</i18n>
